import {schema} from '~hooks/useForm'

export const loginSchema = schema.object({
	email: schema.string().email().nonempty(),
	password: schema.string().nonempty(),
})

export const resetPasswordSchema = schema.object({
	email: schema.string().email().nonempty(),
})

export const confirmPasswordSchema = schema.object({
	token: schema.string().optional().nullable(),
	password: schema.string().nonempty(),
})
