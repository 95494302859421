import {useAtomValue} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'

import {resolver, schema, useForm} from '~hooks/useForm'

import {confirmPasswordSchema} from '~schemas/auth'

import {Button, Input, Field, PasswordInput} from '~ui/Forms'
import {Icon} from '~ui/Modules'

interface FormProps {
	token: string
	onSubmit: (data: FormData) => Promise<void>
	className?: string
}

type FormData = schema.infer<typeof confirmPasswordSchema>

const ConfirmPasswordForm = ({token, onSubmit, className = '', ...props}: FormProps) => {
	const locale = useAtomValue(LocaleState)
	const app = useAtomValue(AppState)

	const {
		register: formRegister,
		handleSubmit: formHandleSubmit,
		formState: {errors: formErrors},
	} = useForm<FormData>({
		resolver: resolver(confirmPasswordSchema),
		defaultValues: {
			token: token,
		},
	})

	return (
		<div className={'tw-flex tw-w-[370px] tw-flex-col tw-gap-y-[20px]' + (className && ' ' + className)} {...props}>
			<div className="tw-flex tw-justify-center">
				<Icon className="tw-h-[40px] tw-w-[192px] tw-flex-shrink-0 tw-text-logo" name="#icon-app-logo" />
			</div>
			<form onSubmit={formHandleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-y-[40px]">
				<h1 className="tw-text-center tw-text-[36px] tw-font-normal">{locale['INDEX']['FORMS']['CONFIRM_PASSWORD']['TITLE']}</h1>
				<div className="tw-flex tw-flex-col tw-gap-y-[20px]">
					<Field name="password" error={formErrors?.['password']} label={locale['INDEX']['FORMS']['LOGIN']['FIELDS']['PASSWORD']['LABEL']} className="ui-field-primary">
						<PasswordInput
							type="password"
							className="ui-input-primary tw-max-w-full"
							placeholder={locale['INDEX']['FORMS']['LOGIN']['FIELDS']['PASSWORD']['PLACEHOLDER']}
							autoComplete="on"
							{...formRegister('password')}
						/>
					</Field>
					<Button type="submit" className="ui-button-primary tw-w-full" loading={app['loading']}>
						{locale['INDEX']['FORMS']['CONFIRM_PASSWORD']['SUBMIT']}
					</Button>
				</div>
			</form>
		</div>
	)
}

export default ConfirmPasswordForm
