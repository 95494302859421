import Head from 'next/head'
import {useRouter} from 'next/router'
import {useState, useEffect} from 'react'

import {confirmPassword, login, resetPassword} from '~actions/auth'

import {useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'
import {UserState} from '~states/User'

import {schema} from '~hooks/useForm'

import {confirmPasswordSchema, loginSchema} from '~schemas/auth'

import {Notification} from '~ui/Modules'

import LoginForm from '~chunks/_app/form/Login'
import ResetPasswordForm from '~chunks/_app/form/ResetPassword'
import ConfirmPasswordForm from '~chunks/_app/form/ConfirmPassword'

type FormData = schema.infer<typeof loginSchema>
type ConfirmFormData = schema.infer<typeof confirmPasswordSchema>

interface Props {
	formInitial?: string
	tokenInitial?: string
}

export async function getServerSideProps(context) {
	const data = {}

	data['formInitial'] = context['query']?.['form'] || 'login'
	data['tokenInitial'] = context['query']?.['token'] || ''

	return {
		props: data,
	}
}

function IndexPage({formInitial = '', tokenInitial = ''}: Props) {
	const locale = useAtomValue(LocaleState)
	const setUser = useSetAtom(UserState)
	const setApp = useSetAtom(AppState)

	const [form, setForm] = useState(formInitial)

	const router = useRouter()

	useEffect(() => {
		setForm(formInitial)
	}, [formInitial])

	const loginHandler = async (data: FormData) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		let response = {}

		response = await login(data)

		if (response['success']) {
			setUser((user) => ({
				...user,
				profile: response['body'],
			}))

			await router.push(router['asPath'].split('?')[0] == '/' ? '/souvenirs/orders/' : router['asPath'])
		} else {
			switch (response['status']) {
				case 401:
					Notification.error(locale['INDEX']['FORMS']['LOGIN']['NOTIFICATIONS']['NOT_CORRECT'])

					break

				default:
					Notification.error(locale['INDEX']['FORMS']['LOGIN']['NOTIFICATIONS']['DEFAULT_FAILED'])
			}
		}

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	const resetPasswordHandler = async (data: FormData) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		let response = {}

		response = await resetPassword(data)

		if (response['success']) {
			//! Добавление нотификации
			Notification.success(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['SUCCESS'])

			setForm('login')
		} else {
			switch (response['status']) {
				case 404:
					Notification.error(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['NOT_FOUND'])

					break

				case 429:
					Notification.error(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['ALREADY_SENT'])

					break

				default:
					Notification.error(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['DEFAULT_FAILED'])
			}
		}

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	const confirmPasswordHandler = async (data: ConfirmFormData) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		let response = {}

		response = await confirmPassword({password: data['password']}, data['token'])

		if (response['success']) {
			Notification.success(locale['INDEX']['FORMS']['CONFIRM_PASSWORD']['NOTIFICATIONS']['SUCCESS'])

			setForm('login')
		} else {
			switch (response['status']) {
				case 404:
					Notification.error(locale['INDEX']['FORMS']['CONFIRM_PASSWORD']['NOTIFICATIONS']['NOT_FOUND'])

					break

				default:
					Notification.error(locale['INDEX']['FORMS']['CONFIRM_PASSWORD']['NOTIFICATIONS']['DEFAULT_FAILED'])
			}
		}

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	const formSwitchHandler = async (form: string) => {
		//Т.к. мы не хотим триггерить полоску загрузки при смене формы, то изменения квери сеттим без некст-роутера:
		//TODO: rework, then rework progressbar + next.router

		if (typeof window !== 'undefined') {
			const window_query = new URLSearchParams(window.location.search)

			if (form == 'login') {
				window_query.delete('form')
			} else {
				window_query.set('form', form)
			}

			window.history.replaceState({}, '', window.location.pathname + (window_query.toString() ? '?' + window_query.toString() : '') + window.location.hash)
		}

		setForm(form)
	}

	return (
		<>
			<Head>
				<title>{locale['INDEX']['TITLE'] + ' :: ' + process.env.NEXT_PUBLIC_APP_NAME}</title>
				<meta property="og:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
			</Head>

			<div className="ui-form-layout">
				{form == 'login' && <LoginForm setForm={formSwitchHandler} onSubmit={loginHandler} />}

				{form == 'reset-password' && <ResetPasswordForm setForm={formSwitchHandler} onSubmit={resetPasswordHandler} />}

				{form == 'confirm-password' && <ConfirmPasswordForm token={tokenInitial} onSubmit={confirmPasswordHandler} />}
			</div>
		</>
	)
}
export default IndexPage
